/**actions required for user list api */

import { createAction, props } from '@ngrx/store';
import { dynamicObject, ErrorInAPI, paginationQueryParam, SortAndPaginationModal } from '@models/common.model';
import { TransportOrderActionId, TransportOrderDetail, TransportOrderListAPIResponse, TransportOrderListPaginationResponse } from '@models/transport-order.model';

/** list order action */
export const loadTransportOrder = createAction(
  '[API] Load TransportOrder',
  props<{
    search?: string | '',
    pagination: paginationQueryParam;
    searchFilterObj?: dynamicObject
  }>()
);

export const loadTransportOrderSuccess = createAction(
  '[API] Load TransportOrder Success',
  props<{ transportOrdersList: TransportOrderListPaginationResponse }>()
);

export const loadTransportOrderError = createAction(
  '[API] Load TransportOrder Error',
  props<{ error: ErrorInAPI }>()
);

/** create order action */
export const addTransportOrder = createAction(
  '[API] Add TransportOrder',
  props<{ transportOrder: TransportOrderDetail }>()
);

export const addTransportOrderSuccess = createAction(
  '[API] Add TransportOrder Success',
  props<{ transportOrder: TransportOrderDetail }>()
);

export const addTransportOrderError = createAction(
  '[API] Add TransportOrder Error',
  props<{ error: ErrorInAPI }>()
);

/** get order detail action */
export const getTransportOrder = createAction(
  '[API] Get TransportOrder Detail',
  props<TransportOrderActionId>()
);

export const getTransportOrderSuccess = createAction(
  '[API] Get TransportOrder Detail Success',
  props<{ transportOrder: TransportOrderDetail }>()
);

export const getTransportOrderError = createAction(
  '[API] Get TransportOrder Detail Error',
  props<{ error: ErrorInAPI }>()
);

/**update order detail */
export const updateTransportOrder = createAction(
  '[API] Update TransportOrder Detail',
  props<{ id: string | number, transportOrder: TransportOrderDetail }>()
);

export const updateTransportOrderSuccess = createAction(
  '[API] Update TransportOrder Detail Success',
  props<{ transportOrder: TransportOrderDetail }>()
);

export const updateTransportOrderError = createAction(
  '[API] Update TransportOrder Detail Error',
  props<{ error: ErrorInAPI }>()
);

export const loadTransportOrderWithSearch = createAction(
  '[API] Load Transport Order With Search', props<{ search?: string, pagination: SortAndPaginationModal, searchFilterObj?: dynamicObject }>()
);

export const loadTransportOrderWithSearchSuccess = createAction(
  '[API] Load Transport Order With Search Success',
  props<{ transportOrdersList: TransportOrderListPaginationResponse }>()
);

export const resetStateAfterOperation = createAction(
  '[API] Reset State After Operation'
);

export const transportOrderOperationMessage = createAction(
  '[API] TransportOrder Operation Message',
  props<{ message: string }>()
);


export const resetStateSuccess = createAction('[API] Reset State Success');

export const TransportOrderActions = {
  loadTransportOrder,
  loadTransportOrderSuccess,
  loadTransportOrderError,
  resetStateAfterOperation,
  resetStateSuccess,
  addTransportOrder,
  addTransportOrderSuccess,
  addTransportOrderError,
  getTransportOrder,
  getTransportOrderError,
  getTransportOrderSuccess,
  updateTransportOrder,
  updateTransportOrderError,
  updateTransportOrderSuccess,
  transportOrderOperationMessage,
  loadTransportOrderWithSearch,
  loadTransportOrderWithSearchSuccess
};
